<template>
    <vue-multi-select
        v-model="selectPerujukMultiSelect"
        :search-change="asyncFind"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="true"
        :searchable="true"
        :options="perujuks"
        :show-labels="false"
        :disabled="disabled"
        :validation="validation"
    >
    <template v-slot:singleLabelTemplate="{data}">
            <span class="multioption-title"
                >{{ data.option.firstName }} {{ data.option.lastName }}</span
            >
            <span class="multioption-subtitle"> - {{
                data.option.alamatNamas
            }}</span>
        </template>
        <template v-slot:optionsTemplate="{data}">
            <div class="option__desc">
                <span class="multioption-title"
                    >{{ data.option.firstName }}
                    {{ data.option.lastName }}</span
                >
                <span class="multioption-subtitle"> - {{
                    data.option.alamatNamas
                }}</span>
            </div>
        </template>
        <span slot="noResult">Tidak Ada nama perujuk.</span>
    </vue-multi-select>
</template>

<script>
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const PerujukRepository = RepositoryFactory.get("perujuk");
// import Multiselect from "vue-multiselect";
import VueMultiSelect from './VueMultiSelect.vue';

export default {
    provide() {
        return {
            $validator: this.$validator
        };
    },
    inject: ["$validator"],
    components: {
        VueMultiSelect,
    },
    computed: {
        selectPerujukMultiSelect: {
            get() {
                return this.selectPerujuk;
            },
            set(val) {
                this.$emit("setPerujuk", val);
            },
        },
    },
    data() {
        return {
            isLoading: false,
            perujuks: [],
        };
    },
    props: {
        selectPerujuk: {
            type: Object,
            default: () => {},
        },
        validation: {
            type: [String, Object],
            default:''
        },
        disabled:{
            type:Boolean,
            default:false
        }
    },
    mounted () {
        if (this.perujuks.length == 0) {
            this.getData()
        }
    },
    methods: {
        customLabel({ title, desc }) {
            return `${title} – ${desc}`;
        },
        getData () {
        PerujukRepository.getPerujukWithQuery('', false)
            .then((response) => {
                this.isLoading = false;
                this.perujuks = response.data;
            })
            .catch((error) => {
                this.isLoading = false;
                var msg = error.message ? error.message : error;
                toastr.error("Kategori : " + msg);
            });
        },
        asyncFind(query) {
            this.isLoading = true;
            PerujukRepository.getPerujukWithQuery(query, true)
                .then((response) => {
                    this.isLoading = false;
                    this.perujuks = response.data;
                })
                .catch((error) => {
                    this.isLoading = false;
                    var msg = error.message ? error.message : error;
                    toastr.error("Kategori : " + msg);
                });
        },
    },
};
</script>
