<template>
  <div>
        <vue-multi-select
            :options="listOptions"
            v-model="selectedValue"
            :multiple="multiple"
            :trackBy="trackBy"
            :label="label"
            :group-label="groupLabelSelect"
            :group-select="groupSelect"
            :group-values="groupValueSelect"
            :internal-search="internalSearch"
            :search-change="searchChangeSelect"
            :select="select"
            :remove="remove"
            :validation="validation"
            :validationName="validationName"
            :name="name"
            :scope="scope"
            :placeholder="placeholder"
            :loading="loading"
            :valueLabel="valueLabel"
            :disabled="disabled"
        ></vue-multi-select>
  </div>
</template>

<script>
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const TindakanRepository = RepositoryFactory.get("tindakan");
import VueMultiSelect from './VueMultiSelect.vue';

export default {
  provide() {
        return {
            $validator: this.$validator
        };
    },
    inject: ["$validator"],
    data() {
        return {
            optionsTindakans: [],
            cloneOptions: [],
            loading: false,
        }
    },
    components: {
        VueMultiSelect,
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: [Array, Number, String, Object],
        options: {
            type:Array
        },
        grouping: {
            type: Boolean,
            default: false
        },
        multiple:{
            type: Boolean,
            default: false
        },
        trackBy: {
            type: String,
            default: 'id'
        },
        label:{
            type: String,
            default: 'nama'
        },
        groupLabel:{
            type: String,
            default: 'nama'
        },
        groupSelect: {
            type: Boolean,
            default: false
        },
        groupValue: {
            type: String,
            default: 'subTindakans'
        },
        validationName:{
            type:String,
            default:'Tindakan'
        },
        validation:{
            type: [String, Object],
            default:''
        },
        name:{
            type:String,
            default: 'tindakan'
        },
        scope: {
            type: String,
            default:null
        },
        select:{
            type: Function,
            default:() => {}
        },
        remove:{
            type: Function,
            default:() => {}
        },
        internalSearch: {
            type: Boolean,
            default: true
        },
        searchChange: {
            type: Function
        },
        disabled:{
            type:Boolean,
            default:false
        },
        placeholder:{
            type: String
        },
        valueLabel:{
            type: String
        }
    },
    computed: {
        selectedValue: {
            get () {
                return this.value
            },
            set (newValue) {
                this.$emit('change', newValue);
            }
        },
        listOptions:function() {
            return (this.options != undefined) ? this.options : this.optionsTindakans;
        },
        groupLabelSelect: function(){
            return (this.grouping) ? this.groupLabel : undefined
        },
        groupValueSelect: function(){
            return (this.grouping) ? this.groupValue : undefined
        },
        searchChangeSelect: function(){
            if(this.grouping){
                return (this.searchChange != undefined) ? this.searchChange : this.searchChangeGroup;
            } else {
                return this.searchChange;
            }
        }
    },
  methods: {

    async getData() {
        this.loading=true;
        new Promise((resolve, reject) => {
            TindakanRepository.get({}, resolve, reject );
        }).then(response => {
            if(this.grouping){
                const tindakan = response.data;
                const parentTindakan = tindakan.filter(x => x.parent === null);
                parentTindakan.forEach(parent => {
                    const sub = tindakan.filter(y => {
                        if (y.parent) {
                            return y.parent.id === parent.id;
                        }
                    });
                    parent.subTindakans = sub;
                });
                this.optionsTindakans = parentTindakan;
                this.cloneOptions = [...this.optionsTindakans];
            }else{
                this.optionsTindakans = response.data;
            }
            this.loading=false;

        }).catch(error => {
            var msg = (error.message) ? error.message : error;
            toastr.error('Tindakan : ' + msg);
        })
    },
    searchChangeGroup(query){
        this.optionsTindakans = this.cloneOptions.filter(x => {
            if(x.subTindakans.find(y => y.nama.toLowerCase().indexOf(query.toLowerCase()) > -1 )){
                return x
            } else if ( x.nama.toLowerCase().indexOf(query.toLowerCase()) > -1 ) {
                return x;
            }
        })
    },
  },
  mounted() {
    if (this.options == undefined) {
        this.getData();
    }
  }
};
</script>

