export default function SkemaRujukanFee() {
    return {
        id: null,
        biaya: null,
        skemaRujukan: null,
        kelas: null,
        tindakan: null,
        isDeleted: null,
        persentase: null,
        usePersentase: null,
        biayaMin: null,
        biayaMax: null
    };
}
