<template>
    <div>
        <base-header :base-title="headTitle"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <router-link
                                class="btn btn-default btn-bold btn-upper btn-font-sm mr-2"
                                :to="{ name: 'transaksi-referral' }"
                            >
                                <i class="flaticon2-back"></i>
                                {{ $t('button.previous') }}
                            </router-link>
                            <button class="btn btn-default btn-bold btn-upper btn-font-sm" @click="save()" :disabled="allow">
                                <i class="fa fa-save"></i>
                                 {{ $t('button.save') }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body" v-if="isLoaded">
                   <form>
                    <div class="row">
                        <div class="col-lg-2"></div>
                        <div class="col-lg-8">
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label">{{ $t('common.medicalNo') }}:</label>
                                <div class="col-lg-8">
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="transaksiReferral.medicalRecordNo"
                                        placeholder="Medical Record"
                                        name="medicalRecordNO"
                                        :disabled="isDisabled1"
                                        />
                                </div>
                            </div>
                            <div class="form-group row" v-if="transaksiReferral.id">
                                <label class="col-lg-3 col-form-label">{{ $t('common.nomorRegistrasi') }}:</label>
                                <div class="col-lg-8">
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="transaksiReferral.nomorRegistrasi"
                                        placeholder="Nomor Registrasi"
                                        name="nomorRegistrasi"
                                        v-validate="`${(isNextJejak && (nextJejak == 'PASIEN_DATANG')) ? 'required' : ''}`"
                                        data-vv-as="nomorRegistrasi"
                                        :disabled="isDisabled3"
                                        :class="{'is-invalid': errors.has('nomorRegistrasi') }"
                                        />
                                    <div v-show="errors.first('nomorRegistrasi')" class="invalid-feedback">Nomor Registrasi harus diisi.</div>
                                      <span
                                        v-show="duplicateError"
                                        style="fontSize:80%; color:#fd397a; marginTop:0.25rem; widht:100%; fontWeight:400;"
                                      >Nomor Registrasi Sudah Terdaftar</span>
                                </div>
                            </div>
                            <div class="form-group row" v-else>
                                <label class="col-lg-3 col-form-label">{{ $t('common.nomorRegistrasi') }}:</label>
                                <div class="col-lg-8">
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="transaksiReferral.nomorRegistrasi"
                                        placeholder="Nomor Registrasi"
                                        name="nomorRegistrasi"
                                        :disabled="isDisabled3"
                                        />
                                      <span
                                        style="fontSize:80%; color:#fd397a; marginTop:0.25rem; widht:100%; fontWeight:400;"
                                      >Nomor Registrasi bisa ditambahkan saat berubah status pasien datang.</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label">{{ $t('common.name') }}<span style="color: red;">*</span>:</label>
                                <div class="col-lg-8">
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="transaksiReferral.nama"
                                        placeholder="Nama"
                                        v-validate="'required'"
                                        data-vv-as="Nama"
                                        name="Nama"
                                        :disabled="isDisabled1"
                                        :class="{'is-invalid': errors.has('Nama') }"/>
                                    <div v-show="errors.first('Nama')" class="invalid-feedback">{{ errors.first('Nama') }}</div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label">{{ $t('common.tanggalLahir') }}<span style="color: red;">*</span>:</label>
                                <div class="col-lg-8">
                                    <date-picker
                                        ref="datePickerDobVue"
                                        :changedValue.sync="transaksiReferral.tanggalLahir"
                                        id-date-picker="tanggal_lahir_pasien"
                                        :picker-value="transaksiReferral.tanggalLahir"
                                        :is-validate="true"
                                        :is-disabled="isDisabled1"
                                        place-holder="DD/MM/YYYY"
                                        start-date
                                        end-date="-0d"
                                    ></date-picker>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label">{{ $t('common.phoneNo') }}:</label>
                                <div class="col-lg-8">
                                    <phone-number
                                        :is-disabled="isDisabled1"
                                        :data="transaksiReferral.telepon"
                                        :changedValue.sync="transaksiReferral.telepon">
                                    </phone-number>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label">{{ $t('common.alamat') }}:</label>
                                <div class="col-lg-8">
                                    <textarea
                                        rows="5"
                                        class="form-control"
                                        :disabled="isDisabled1"
                                        v-model="transaksiReferral.alamat"
                                        placeholder="Alamat"
                                        name="alamat"
                                        />
                                </div>
                            </div>
                            <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg"></div>
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label">{{ $t('common.perujuk') }}<span style="color: red;">*</span>:</label>
                                <div class="col-lg-8">
                                    <div class="row">
                                        <div class="col-12">
                                            <perujuk-select
                                                ref="perujukPicker"
                                                :select-perujuk="perujuk"
                                                :validation="'required'"
                                                :disabled="isDisabled1"
                                                @setPerujuk="setPerujuk"
                                            >
                                            </perujuk-select>
                                        </div>
                                    </div>
                                    <div class="row mt-2" v-if="checkPermission('RegistrasiResource.POST.RegistrasiByInternal')">
                                        <div class="col-12">
                                            <button @click="openModal"
                                                type="button"
                                                class="btn btn-sm"
                                                :class="{'btn-label-success' : !isDisabled1, 'pointer-not-allowed': isDisabled1}"
                                                :disabled="isDisabled1"
                                             ><i class="flaticon2-plus"></i> {{ $t('button.addPerujuk') }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label">{{ $t('common.diagnosa') }}<span style="color: red;">*</span>:</label>
                                <div class="col-lg-8">
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="transaksiReferral.diagnosa"
                                        placeholder="Diagnosa"
                                        v-validate="'required'"
                                        data-vv-as="Diagnosa"
                                        name="Diagnosa"
                                        :disabled="isDisabled1"
                                        :class="{'is-invalid': errors.has('Diagnosa') }"/>
                                    <div v-show="errors.first('Diagnosa')" class="invalid-feedback">{{ errors.first('Diagnosa') }}</div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label">{{ $t('common.catatan') }}:</label>
                                <div class="col-lg-8">
                                    <textarea
                                        rows="5"
                                        class="form-control"
                                        v-model="transaksiReferral.catatan"
                                        placeholder="Catatan"
                                        name="catatan"
                                        :disabled="isDisabled1"
                                        />
                                </div>
                            </div>
                            <div class="form-group row" v-show="checkPermission('TransaksiReferralService.VIEW.DataPenunjang')">
                                <label class="col-lg-3 col-form-label">{{ $t('common.dataPenunjang') }}:</label>
                                <div class="col-lg-8">
                                       <div
                                            class="dropzone"
                                            :action="urlUpload"
                                            id="kt-dropzone-one"
                                            >
                                            <div class="dropzone-msg dz-message needsclick">
                                                <h1><i
                                                    class="fa fa-upload"
                                                    aria-hidden="true"
                                                ></i></h1>
                                                <h3 class="dropzone-msg-title">Upload</h3>
                                                <span class="dropzone-msg-desc">Tarik gambar ke sini atau klik untuk upload.</span>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg"></div>
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label">{{ $t('common.poly') }}<span style="color: red;">*</span>:</label>
                                <div class="col-lg-8">
                                    <tujuan-rujukan-select
                                        v-model="transaksiReferral.tujuanRujukan"
                                        :validation="'required'"
                                        :disabled="isDisabled1"
                                    ></tujuan-rujukan-select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label">{{ $t('common.guarantor') }}<span style="color: red;">*</span>:</label>
                                <div class="col-lg-8">
                                     <jenis-penjamin-select
                                        v-model="transaksiReferral.jenisPenjamin"
                                        :validation="'required'"
                                        :disabled="isDisabled1"
                                    ></jenis-penjamin-select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label">{{ $t('common.tindakan') }}:</label>
                                <div class="col-lg-8">
                                      <tindakan-select
                                        v-model="transaksiReferral.tindakan"
                                        :internal-search="false"
                                        :grouping="true"
                                        :disabled="isDisabled1"
                                    ></tindakan-select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label">Kelas:</label>
                                <div class="col-lg-8">
                                    <kelas-select
                                        v-model="transaksiReferral.kelas"
                                        :disabled="isDisabled1"
                                    ></kelas-select>
                                </div>
                            </div>
                            <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg"></div>
                            <template v-if="checkPermission('TransaksiReferralService.EDIT.Fee')">
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label">{{ $t('common.totalBiaya') }}:</label>
                                <div class="col-lg-8">
                                    <cleave class="form-control"
                                        :options="configNumber"
                                        v-model.number="transaksiReferral.totalBiaya"
                                        :class="{'is-invalid': isRequiredTotalBiaya }"
                                        :disabled="isDisabled2"
                                        @keyup.native="calculateFeePersen()"
                                        name="total"
                                        placeholder="Total Biaya">
                                    </cleave>
                                    <div v-show="isRequiredTotalBiaya" class="invalid-feedback">
                                        Skema yang dipilih menggunakan persentase, silahkan isi terlebih dahulu total biaya
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label">{{ $t('common.fee') }}: <br/>
                                    <small v-if="transaksiReferral.skemaRujukanFee">
                                        ( Skema terpilih <strong>{{ transaksiReferral.skemaRujukanFee.skemaRujukan.nama }}</strong>)
                                    </small>
                                    <small v-else>
                                        ( Belum ada skema terpilih / input manual ) 
                                    </small>
                                </label>
                                <div class="col-lg-8">
                                    <div class="input-group mb-2">
                                        <cleave class="form-control"
                                            :disabled="isDisabled2"
                                            :options="configNumber"
                                            v-model.number="transaksiReferral.fee"
                                            @keyup.native="resetSkemaFee()"
                                            name="fee"
                                            placeholder="Fee">
                                        </cleave>
                                        <div class="input-group-append">
                                            <button
                                                class="btn btn-secondary"
                                                type="button"
                                                :disabled="isDisabled2"
                                                @click="showModal">Cari Fee</button>
                                        </div>
                                    </div>
                                    <template v-if="foundSkemaRekomendasi && !isDisabled2">
                                        <div v-if="showRecommendation">
                                            <label class="form-control-label" >Rekomendasi Fee</label>
                                            <ul class="pl-4">
                                                <li v-for="fee in listFeeRecommendation" :key="fee.id">
                                                    <label v-if="!fee.usePersentase" class="form-control-label">
                                                        {{ fee.skemaRujukan.nama }} - {{ fee.biaya | currency }}
                                                    </label>
                                                    <label v-else class="form-control-label">
                                                        {{ fee.skemaRujukan.nama }} - Persentase : {{ fee.persentase}} | Max Biaya : {{ fee.biayaMax | currency }}  | Min Biaya : {{ fee.biayaMin | currency }}
                                                    </label>
                                                    <a class="kt-font-bold" :class="{'kt-font-success': !isDisabled2  }" @click="updateFee(fee)" style="cursor: pointer">( Pilih )</a>
                                                </li>
                                            </ul>
                                            <label class="form-control-label" v-if="listFeeRecommendation.length == 0">
                                                Tidak ada fee yang ditemukan silahkan cari fee dengan menekan
                                                <a @click="showModal" class="kt-font-bold" :class="{'kt-font-success': !isDisabled2  }" style="cursor: pointer" >Cari Fee</a>
                                            </label>
                                        </div>
                                        <a v-else @click="showRecommendation = true" class="kt-font-bold kt-font-success" style="cursor: pointer" >[ Tampilkan Rekomendasi ]</a>

                                    </template>
                                    <span class="text text-danger form-control-label" v-if="!foundSkemaRekomendasi && !isDisabled2">
                                        <template v-if="transaksiReferral.jenisPenjamin != null && transaksiReferral.perujuk != null">
                                        Kebijakan fee untuk jenis {{ transaksiReferral.jenisPenjamin.nama }} dan
                                        kategori {{ transaksiReferral.perujuk.kategoriNama }}
                                        pada periode ini tidak ditemukan, silahkan buat kebijakan fee
                                        <router-link to="/skema-rujukan/add">disini</router-link>
                                        </template>
                                    </span>
                                </div>
                            </div>
                                <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg"></div>
                            </template>
                            <template v-if="showNextJejak">
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label">{{ $t('common.pilihan') }}:</label>
                                <div class="col-lg-8">
                                    <div class="kt-checkbox-inline">
                                        <label class="kt-checkbox">
                                            <input type="checkbox" v-model="isNextJejak"/>
                                             Update status ke {{ $t('EnumStage.'+nextJejak)}}
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg"></div>
                            </template>
                            <template v-if="transaksiReferral.id">
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label kt-font-boldest">{{ $t('common.jejaks') }}</label>
                                <div class="col-lg-8">
                                    <list-jejak :jejaks="transaksiReferral.jejaks"></list-jejak>
                                </div>
                            </div>
                            </template>
                        </div>
                        <div class="col-lg-2"></div>
                    </div>
                   </form>
                </div>
                <div v-else class="m-auto p-5 d-flex flex-row h-100">
                    <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                    <h5 class="my-auto pl-5">Loading ...</h5>
                </div>
                <form-fee
                    v-if="isShowModal"
                    @update="updateFee"
                    :show-modal.sync="isShowModal"
                    :modal-name="modalName"
                    :modal-data="listSkema"
                    :selected="transaksiReferral.skemaRujukanFee"
                ></form-fee>
                <form-modal
                    v-if="openPerujukModal"
                    :show-modal.sync="openPerujukModal"
                    @event="resetPerujuk"
                    :modal-name="'modalPerujuk'"
                    :modal-data="dataPerujuk"
                    :password-bcn="true"
                ></form-modal>
                <form-image
                    v-if="openModalImage"
                    :show-modal.sync="openModalImage"
                    :modal-name="'modalImage'"
                    :modal-data="imageLink"
                ></form-image>
            </div>
        </div>
    </div>
</template>

<script>
import { Validator } from "vee-validate";
import SweetAlertTemplate from "./../_general/SweetAlert";
import BlockUI from "../_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const TransaksiReferralRepository = RepositoryFactory.get("transaksiReferral");
const SkemaRujukanRepository = RepositoryFactory.get("skemaRujukan");
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import TujuanRujukanSelect from "./../_select/Poli.vue";
import JenisPenjaminSelect from "./../_select/JenisPenjamin";
import PerujukSelect from "./../_select/SearchPerujuk.vue";
import TindakanSelect from "./../_select/Tindakan";
import KelasSelect from "./../_select/Kelas.vue";
import DatePicker from "./../_general/DatePicker";
import PhoneNumber from "./../_general/PhoneNumber";
import TransaksiReferral from "./../../model/transaksi-referral-model";
import SkemaRujukanFee from "./../../model/skema-rujukan-fee-model";
import Photo from "./../../model/photo-model";
import LoadSpinner from "./../_general/LoadSpinner";
import { isNullOrUndefined } from "util";
import JejakEnum from "./../../constants/jejak-enum";
import ListJejak from "./_components/ListJejak";
import jejaks from "./../../services/jejak.service";
import jejakEnum from './../../constants/jejak-enum';
import TujuanRujukanRepository from '../../repositories/TujuanRujukanRepository';

export default {
  components: {
    BaseTitle,
    BaseHeader,
    TujuanRujukanSelect,
    JenisPenjaminSelect,
    DatePicker,
    PhoneNumber,
    PerujukSelect,
    TindakanSelect,
    KelasSelect,
    LoadSpinner,
    FormFee: () => ({
      component: new Promise(function(resolve, reject) {
        setTimeout(function() {
          resolve(
            import(/* webpackChunkName: "modal-fee" */ "./_components/FormFee.vue")
          );
        }, 1000);
      }),
      loading: LoadSpinner
    }),
    ListJejak,
    FormModal: () => ({
      component: new Promise(function(resolve, reject) {
        setTimeout(function() {
          resolve(
            import(/* webpackChunkName: "modal-registrasi-perujuk" */ "./../perujuks/_components/FormModal.vue")
          );
        }, 1000);
      }),
      loading: LoadSpinner
    }),
    FormImage: () => ({
      component: new Promise(function(resolve, reject) {
        setTimeout(function() {
          resolve(
            import(/* webpackChunkName: "modal-view-image" */ "./_components/FormImage.vue")
          );
        }, 1000);
      }),
      loading: LoadSpinner
    }),
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  data() {
    var vx = this;
    return {
      isComplete: true,
      transaksiReferral: new TransaksiReferral(),
      listPhoto: [],
      isLoaded: false,
      headTitle: "Transaksi Referral",
      configNumber: {
        numeral: true,
        numeralPositiveOnly: true,
        numeralDecimalMark: ",",
        delimiter: "."
      },
      indexTable: 0,
      foundSkemaRekomendasi: false,
      urlUpload: process.env.VUE_APP_API_URL + "/gallery/api/photos",
      urlImage:  process.env.VUE_APP_API_URL + "/gallery/api/image/alt/",
      listSkema: [],
      listFeeRecommendation: [],
      showRecommendation: true,
      isRequiredTotalBiaya: false,
      isShowModal: false,
      modalName: "modal_fee",
      showNextJejak: false,
      isNextJejak: false,
      nextJejak: null,
      isDisabled1: true,
      isDisabled2: true,
      isDisabled3: false,
      dataPerujuk: null,
      openPerujukModal: false,
      imageLink: null,
      openModalImage: false,
      duplicateError: false,
    };
  },
  filters: {
    currency: function(data) {
      if (data !== null) {
        const parts = data.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return "Rp." + parts.join(",");
      }
      return data;
    }
  },
  watch: {
    "transaksiReferral.perujuk": function(val) {
      if (this.transaksiReferral.jenisPenjamin && val) {
        this.onInitRekomendasi();
      }
    },
    "transaksiReferral.jenisPenjamin": function(val) {
      if (this.transaksiReferral.perujuk && val) {
        this.onInitRekomendasi();
      }
    },
    "transaksiReferral.tindakan": function(val) {
      if (this.transaksiReferral.kelas && val) {
        this.makeListRekomensdasi();
      }
    },
    "transaksiReferral.kelas": function(val) {
      if (this.transaksiReferral.tindakan && val) {
        this.makeListRekomensdasi();
      }
    },
    "transaksiReferral.nomorRegistrasi": function(val){
      this.duplicateError = false
      if(val){
        if(this.nextJejak == JejakEnum.PASIEN_DATANG){
          this.isNextJejak = true
        }
      } else {
        this.isNextJejak = false
      }
      if (val !== null) {
        this.transaksiReferral.nomorRegistrasi = val.replaceAll(/\s/g,'')
      }
    },
    // opsi disable field form nomor registrasi kalau pasien belum datang
    // "transaksiReferral.jejakTerakhirStage": function(val){
    //   if(val == JejakEnum.PASIEN_BELUM_MASUK){
    //     this.isDisabled3 = true
    //   } else {
    //     this.isDisabled3 = false
    //   }
    // },
    // isNextJejak: function(val){
    //   if(val){
    //     this.isDisabled3 = false
    //   } else {
    //     this.isDisabled3 = true
    //   }
    // }
  },
  methods: {
    setPerujuk (val) {
      this.perujuk = val
    },
    save: function() {
      var vx = this;
      this.$validator.validateAll().then(result => {
        if (result) {
          const noReg = vx.transaksiReferral.nomorRegistrasi
          vx.transaksiReferral.nomorRegistrasi = noReg ? noReg.replaceAll(/\s/g,'') :null
          if (vx.transaksiReferral.id) {
           new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Perubahan data transaksiReferral akan tersimpan.",
              swalType: "info",
              onConfirmButton: function() {
                blockUI.blockPage();
                new Promise((resolve, reject) => {
                  vx.constructData(resolve);
                }).then(response => {
                  vx.update();
                });
              },
              onCancelButton: function() {
                blockUI.unblockPage();
              }
            });
          } else {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Data transaksiReferral akan ditambahkan.",
              swalType: "info",
              onConfirmButton: function() {
                blockUI.blockPage();
                new Promise((resolve, reject) => {
                  vx.constructData(resolve);
                }).then(response => {
                  vx.create();
                });
              },
              onCancelButton: function() {
                blockUI.unblockPage();
              }
            });
          }
          return;
        }
      });
    },
    async update(){
        var vx = this;
        await TransaksiReferralRepository.update(vx.transaksiReferral)
            .then(response => {
                 instance = new SweetAlert().showInfo({
                    swalTitle: "Berhasil!",
                    swalText: "Perubahan data Transaksi Referral berhasil disimpan.",
                    swalType: "success",
                    onConfirmButton: function () {
                        blockUI.unblockPage();
                        // vx.$router.push({ name: 'transaksi-referral' });
                        vx.$router.push({ name: 'transaksi-referral-view', params: { id: response.data.id } });
                    }
                });

            })
            .catch(err => {
              let error = err.response.data
              var message
              const violations = error.violations ?  error.violations[0].field : false
              if(error.status == 400 && violations){
                message = "Nomor registrasi "+vx.transaksiReferral.nomorRegistrasi+" sudah terdaftar"
                vx.duplicateError = true
              } else {
                message = "Perubahan data Transaksi Referral gagal disimpan."
              }
              new SweetAlert().showInfo({
                swalTitle: "Gagal!",
                swalText: message,
                swalType: "error",
                onConfirmButton: function () {
                  blockUI.unblockPage();
                }
              });
            });
    },
    async create(){
        var vx = this;
        await TransaksiReferralRepository.create(vx.transaksiReferral)
            .then(response => {
                let instance = new SweetAlert().showInfo({
                    swalTitle: "Berhasil!",
                    swalText: "Data Transaksi Referral berhasil ditambahkan.",
                    swalType: "success",
                    onConfirmButton: function () {
                        blockUI.unblockPage();
                        // vx.$router.push({ name: 'transaksi-referral' });
                        vx.$router.push({ name: 'transaksi-referral-view', params: { id: response.data.id } });
                    }
                });
            })
            .catch(error => {
                let instance = new SweetAlert().showInfo({
                    swalTitle: "Gagal!",
                    swalText: "Data Transaksi Referral gagal ditambahkan.",
                    swalType: "error",
                    onConfirmButton: function () {
                        blockUI.unblockPage();
                    }
                });
            });
    },
    constructData: function(resolve) {
      // No Telepon
      this.transaksiReferral.telepon = (this.transaksiReferral.telepon && this.transaksiReferral.telepon.length > 2) ? this.transaksiReferral.telepon : null;
      // Next Jejak
      if (this.isNextJejak) {
        this.transaksiReferral.nextJejak = this.nextJejak;
      } else {
        delete this.transaksiReferral.nextJejak;
      }
      // Fee:
      if (this.checkPermission('TransaksiReferralService.EDIT.Fee')) {
        this.transaksiReferral.fee = this.transaksiReferral.fee == '' ? null : this.transaksiReferral.fee;
        this.transaksiReferral.totalBiaya = this.transaksiReferral.totalBiaya == '' ? null : this.transaksiReferral.totalBiaya;
      } else {
        delete this.transaksiReferral.fee;
        delete this.transaksiReferral.totalBiaya;
        delete this.transaksiReferral.skemaRujukanFee;
      }
      this.transaksiReferral.medicalRecordNo =  this.transaksiReferral.medicalRecordNo == '' ? null : this.transaksiReferral.medicalRecordNo;
      resolve(true);
    },
    resetPerujuk: function(resolve){
      this.transaksiReferral.perujuk = {};
      this.$refs.perujukPicker.getData();
      if(resolve != undefined){
          resolve(true);
      }
    },
    showModal: function() {
      if(!this.isDisabled2){
        this.isShowModal = true;
      }
    },
    openModal: function(){
        if(!this.isDisabled1){
            this.openPerujukModal = true;
        }
    },
    mapperPerujuk (data) {
      let perujuk = {}
      perujuk.id = data.id
      perujuk.firstName = data.firstName
      perujuk.lastName = data.lastName
      perujuk.kategoriId = data.kategoriId
      perujuk.kategoriNama = data.kategoriNama
      perujuk.alamatNamas = data.alamatPerujuks && Array.isArray(data.alamatPerujuks) ? data.alamatPerujuks.map(x => x.nama).join(' | ') : ''
      return perujuk
    },
    getData: function(id) {
      new Promise((resolve, reject) => {
        TransaksiReferralRepository.find(id)
          .then(res => {
            const _respData = res.data
            if ( _respData.fee) {
              _respData.fee = _respData.fee.toString().replace('.', ',')
            }
            if (_respData.totalBiaya) {
               _respData.totalBiaya = _respData.totalBiaya.toString().replace('.', ',')
            }
            this.isLoaded = true;
            this.transaksiReferral = _respData;
            this.transaksiReferral.perujuk = this.mapperPerujuk(_respData.perujuk)
            this.transaksiReferral.telepon = (this.transaksiReferral.telepon != null) ? res.data.telepon.replace(/\D/g,"") : null;
            resolve(true);
          })
          .catch(error => {
            var msg = error.message ? error.message : error;
            this.showToastError("transaksi : " + msg);
          });
      }).then(response => {
        this.onInit();
      });
    },
    onInit: function() {
      var vx = this;

      var myDropzone = new Dropzone("#kt-dropzone-one", {
        paramName: "file", // The name that will be used to transfer the file
        maxFiles: 20,
        maxFilesize: 10, // MB
        addRemoveLinks: true,
        autoProcessQueue: true,
        withCredentials: true,
        createImageThumbnails: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: "Bearer " + localStorage.tokens,
          "cache-control": "no-cache",
          "Content-Type": "application/json;charset=UTF-8"
        },
        accept: function(file, done) {
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function(event) {
              let photo = new Photo();
              var image = new Image();
              image.src = event.target.result;
              image.onload = function(i) {
                photo.image = event.target.result.substring(
                  event.target.result.indexOf(",") + 1
                );
                vx.indexTable++;
                photo.title = file.name;
                photo.description = file.name;
                photo.imageContentType = file.type;
                photo.height = this.height;
                photo.width = this.width;
                photo.taken = moment(file.lastModified);
                photo.uploaded = moment();
                photo.uuid = file.upload.uuid;
                vx.listPhoto.push(photo);
                resolve(true);
              };
            };
          }).then(res => {
            done();
          });
        },
        init: function() {
          this.on("success", function(file, response) {
            const data = {
              data: '',
              dataContentType: response.imageContentType,
              dataUrl: response.alias,
              id: null,
              uuid: file.upload.uuid,
              title: response.title
            };
            vx.transaksiReferral.dataPenunjangs.push(data);
            const i = vx.listPhoto.findIndex(x => x.uuid == file.upload.uuid);
            if (i > -1) {
              vx.listPhoto.splice(i, 1);
            }
            file.previewElement.querySelector("img").src = vx.urlImage + data.dataUrl;
            vx.onThumbnailClick();

          });
          this.on("error", function(file, message) {
            const i = vx.listPhoto.findIndex(x => x.uuid == file.upload.uuid);
            if (i > -1) {
              vx.listPhoto.splice(i, 1);
            }
            $(file.previewElement)
              .addClass("dz-error")
              .find(".dz-error-message")
              .text(
                "Upload Gagal, Silahkan hapus gambar dan coba upload kembali"
              );
          });
          this.on("sending", function(file, xhr, formData) {
            let photo = vx.listPhoto.find(x => x.uuid == file.upload.uuid);
            const x = JSON.stringify(photo);
            xhr.send(x);
          });
          this.on("removedfile", function(file) {
            const j = file.id
              ? vx.transaksiReferral.dataPenunjangs.findIndex(
                  x => x.id == file.id
                )
              : vx.transaksiReferral.dataPenunjangs.findIndex(
                  x => x.uuid == file.upload.uuid
                );
            if (j > -1) {
              vx.transaksiReferral.dataPenunjangs.splice(j, 1);
            }
          });
          this.on("thumbnail", function (file) {

          });
        }
      });
      if (this.transaksiReferral.dataPenunjangs != null && this.transaksiReferral.dataPenunjangs.length > 0) {
        this.transaksiReferral.dataPenunjangs.forEach((value, index) => {
          value.dataUrl = value.dataUrl.toString();
          var fileName = (value.title) ? value.title : 'Image'+index;
          var mockFile = { name: fileName, size: 99999, id: value.id };
          myDropzone.options.addedfile.call(myDropzone, mockFile);
          myDropzone.options.thumbnail.call(
            myDropzone,
            mockFile,
            vx.urlImage + value.dataUrl
          );
        });
        this.onThumbnailClick();
      }
      if (this.transaksiReferral.id) {
        this.showRecommendation = isNullOrUndefined(this.transaksiReferral.skemaRujukanFee) ? true : false;
        this.transaksiReferral.tindakan = isNullOrUndefined(this.transaksiReferral.tindakan) ? null : this.transaksiReferral.tindakan;
        this.transaksiReferral.kelas = isNullOrUndefined(this.transaksiReferral.kelas) ? null : this.transaksiReferral.kelas;
        this.isDisabled1 = jejaks.isAllowDisabled(this.transaksiReferral.jejakTerakhirStage);
        this.isDisabled2 = jejaks.isAllowDisabled2(this.transaksiReferral.jejakTerakhirStage);
        if(this.isDisabled1){
            $(".dz-hidden-input").prop("disabled",true);
            $(".dz-remove").hide();
        }
      } else {
        this.isDisabled1 = false;
        this.isDisabled2 = false;
        this.isDisabled3 = true;
      }
      this.onInitNextJejak();
    },
    onThumbnailClick: function(){
        var vx = this;
        setTimeout(() => {
            $('.dz-image-preview').unbind();
            $('.dz-image-preview').click(function(){
                vx.openImageModal($(this).find('img').attr('src'), $(this).find('img').attr('alt'));
            })
        }, 1000);
    },
    openImageModal: function(link, title) {
        this.imageLink = {
            link: link,
            title: title
        }
        this.openModalImage = true;
    },
    onInitNextJejak: function() {
      if (
        this.checkPermission("BackOffice.TransaksiReferral.Add.Next.Jejak") &&
        this.transaksiReferral.jejakTerakhirStage != null &&
        ![
          JejakEnum.TIDAK_JADI_DATANG,
          JejakEnum.DIRUJUK_KETEMPAT_LAIN,
          JejakEnum.SUDAH_TERBAYARKAN,
          JejakEnum.CLOSED
        ].includes(this.transaksiReferral.jejakTerakhirStage)
      ) {
        this.showNextJejak = true;
        if (
          this.transaksiReferral.jejakTerakhirStage ==
          JejakEnum.PASIEN_BELUM_MASUK
        ) {
          this.nextJejak = JejakEnum.PASIEN_DATANG;
        } else if (
          this.transaksiReferral.jejakTerakhirStage == JejakEnum.PASIEN_DATANG
        ) {
          this.nextJejak = JejakEnum.DALAM_PERAWATAN;
        } else if (
          this.transaksiReferral.jejakTerakhirStage == JejakEnum.DALAM_PERAWATAN
        ) {
          this.nextJejak = JejakEnum.PASIEN_PULANG;
        } else if (
          this.transaksiReferral.jejakTerakhirStage == JejakEnum.PASIEN_PULANG
        ) {
          this.nextJejak = JejakEnum.DALAM_PROSES_PEMBAYARAN;
        } else if (
          this.transaksiReferral.jejakTerakhirStage ==
          JejakEnum.DALAM_PROSES_PEMBAYARAN
        ) {
          this.nextJejak = JejakEnum.SUDAH_TERBAYARKAN;
        }
      }
    },
    onInitRekomendasi: function() {
      if (this.checkPermission("TransaksiReferralService.EDIT.Fee")) {
        const date = moment(new Date()).format("YYYY-MM-DD");
        new Promise((resolve, reject) => {
          SkemaRujukanRepository.get(
            {
              jenisPenjaminId: this.transaksiReferral.jenisPenjamin.id,
              kategoriId: this.transaksiReferral.perujuk.kategoriId,
              effectiveDate: date
            },
            resolve,
            reject
          );
        })
          .then(response => {
            this.listSkema = response.data;
            this.makeListRekomensdasi();
          })
          .catch(error => {
            var msg = error.message ? error.message : error;
            this.showToastError("Skema Rujukan : " + msg);
          });
      }
    },
    makeListRekomensdasi: function() {
      if (
        this.listSkema.length > 0 &&
        this.checkPermission("TransaksiReferralService.EDIT.Fee")
      ) {
        this.foundSkemaRekomendasi = true;
        const temp = [];
        this.listSkema.forEach(skema => {
          skema.parentTindakans.forEach(parent => {
            if (this.transaksiReferral.tindakan != null) {
              const filterSub = parent.subTindakans.find(
                x => x.id === this.transaksiReferral.tindakan.id
              );
              if (filterSub && this.transaksiReferral.kelas != null) {
                const filterFee = filterSub.fee.find(
                  m => m.kelasCustom.id === this.transaksiReferral.kelas.id
                );
                if (filterFee) {
                  let nsub = Object.assign({}, filterSub);
                  let kelas = Object.assign({}, filterFee.kelasCustom);
                  delete kelas.kelasOrder;
                  delete kelas.uuid;
                  delete nsub.fee;
                  delete nsub.usePersen;
                  delete nsub.isAll;
                  delete nsub.parent.fee;
                  const skemaFee = new SkemaRujukanFee();
                  skemaFee.id = filterFee.id;
                  skemaFee.biaya = filterFee.biaya;
                  skemaFee.biayaMax = filterFee.biayaMax;
                  skemaFee.biayaMin = filterFee.biayaMin;
                  skemaFee.usePersentase = filterFee.usePersentase;
                  skemaFee.persentase = filterFee.persentase;
                  skemaFee.kelas = kelas;
                  skemaFee.tindakan = nsub;
                  skemaFee.skemaRujukan = skema;
                  temp.push(skemaFee);
                }
              }
            }
          });
        });
        this.listFeeRecommendation = temp.length > 0 ? temp : [];
      } else {
        this.foundSkemaRekomendasi = false;
      }
    },
    updateFee: function(fee) {
      if(!this.isDisabled2){
        this.setFee(fee);
        this.transaksiReferral.tindakan = fee.tindakan;
        this.transaksiReferral.kelas = fee.kelas;
      }
    },
    setFee: function(fee) {
      if (fee) {
        this.transaksiReferral.skemaRujukanFee = fee;
      }
      if (this.transaksiReferral.skemaRujukanFee) {
        this.isRequiredTotalBiaya = false;
        if (this.transaksiReferral.skemaRujukanFee.usePersentase) {
          if (
            this.transaksiReferral.totalBiaya == null ||
            this.transaksiReferral.totalBiaya == ""
          ) {
            this.isRequiredTotalBiaya = true;
          } else {
            this.calculateFeePersen();
          }
        } else {
          this.transaksiReferral.fee = this.transaksiReferral.skemaRujukanFee.biaya;
        }
        this.showRecommendation = false;
      }
    },
    calculateFeePersen: function() {
      this.isRequiredTotalBiaya = false;
      if (this.transaksiReferral.skemaRujukanFee.usePersentase) {
        if (
          this.transaksiReferral.totalBiaya == null ||
          this.transaksiReferral.totalBiaya == ""
        ) {
          this.isRequiredTotalBiaya = true;
        } else {
          const priceFee =
            this.transaksiReferral.skemaRujukanFee.persentase *
            this.transaksiReferral.totalBiaya /
            100;
          if (
            this.transaksiReferral.skemaRujukanFee.biayaMax &&
            priceFee > this.transaksiReferral.skemaRujukanFee.biayaMax
          ) {
            this.transaksiReferral.fee = this.transaksiReferral.skemaRujukanFee.biayaMax;
          } else if (
            this.transaksiReferral.skemaRujukanFee.biayaMin &&
            priceFee < this.transaksiReferral.skemaRujukanFee.biayaMin
          ) {
            this.transaksiReferral.fee = this.transaksiReferral.skemaRujukanFee.biayaMin;
          } else {
            this.transaksiReferral.fee = priceFee;
          }
        }
      }
    },
    checkPermission: function(method) {
      return checkPermission.isCanAccess(method);
    },
    showToastError: function(message) {
      toastr.error(message);
    },
    resetSkemaFee: function(){
        this.transaksiReferral.skemaRujukanFee = null;
    }
  },
  created() {
    if (this.$route.params.id) {
      this.headTitle = "Edit Transaksi Referral";
      this.getData(this.$route.params.id);
    } else {
      new Promise((resolve, reject) => {
        this.headTitle = "Tambah Transaksi Referral";
        this.isLoaded = true;
        resolve(true);
      }).then(response => {
        this.onInit();
      });
    }
  },
  computed:{
      perujuk: {
        get () {
          return this.transaksiReferral.perujuk
        },
        set (val) {
          this.transaksiReferral.perujuk = val
        }
      },
      allow() {
          let disabled = true;
          if(this.isDisabled1 && !this.isDisabled2){
              disabled = false;
          }
          if(!this.isDisabled1 && this.isDisabled2){
              disabled = false;
          }
          if(!this.isDisabled1 && !this.isDisabled2){
              disabled = false;
          }
          return disabled;
      }
  },
  mounted() {

  },
};
</script>

<style>
</style>
